// import React, { useRef } from "react";
import React from "react";
import Layout from "../components/Layout";
// import Modal from "../components/Modal";
// import HeroImage from "../components/HeroImage";
// import { Link } from "gatsby";
import Repubblica from "../components/Repubblica";
// import Frutta from "../components/Frutta";
// import Verdura from "../components/Verdura";
// import Mix from "../components/Mix";
// import Form from "../components/Form";
// import Rating from "../components/Rating";
// import Infographic from "../components/Infographic";
// import Trustpilot from "../components/Trustpilot";
import PL1 from "../components/PL1";
// import Accordion from "../components/Accordion";
import "../css/scroller.css";
// import RecensioneSara from "../components/RecensioneSara";
// import RecensioneSimona from "../components/RecensioneSimona";
// import Arance from "../components/Arance";
// import Limoni from "../components/Limoni";
import PortaUnAmico1 from "../components/PortaUnAmico1";
import PortaUnAmico2 from "../components/PortaUnAmico2";
// import Badges from "../components/Badges";
// import Video from "../components/Video";
// import Badges from "../components/Badges";
// import Bio from "../components/Bio";
import PL2 from "../components/PL2";
import PL3 from "../components/PL3";
import PL4 from "../components/PL4";
import PL5 from "../components/PL5";
import PL6 from "../components/PL6";
import PL7 from "../components/PL7";
import PL8 from "../components/PL8";
// import ReactPlayer from "react-player/file";

export default () => {
    // const modalRef1 = useRef();

    return (
        <Layout>
            <main className="flex-grow flex flex-col justify-evenly m-auto pb-2 px-4 md:px-32">
                <section className="flex flex-wrap items-center justify-center">
                    <div className="text-center mx-0 w-full">
                        <PortaUnAmico1 />
                        <p className="px-4 mt-4">
                            <span className="text-5xl text-center text-black font-serif font-normal">
                                Porta un amico nella famiglia di Passo Ladro e
                                riceverete entrambi <span className="underline">15€ di sconto</span>!
                            </span>{" "}
                        </p>
                        <div className="px-4 mt-8 text-center">
                            {/* <div className="max-w-4xl mx-auto"> */}
                            <a href="https://passoladro.it/registrazione/">
                                <button className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent">
                                    {/* <Link to="/iscriviti"> */}
                                    <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                        Scopri il tuo codice amico
                                    </h2>
                                    {/* </Link> */}
                                </button>
                            </a>
                            {/* </div> */}
                        </div>

                        <p className="px-4 mt-8 text-xl text-gray-700 font-sans">
                            Parlano di noi:
                        </p>
                        <div className="px-4 mt-2 text-xl text-gray-700 font-sans text-center">
                            <a
                                href="https://www.ilgusto.it/2021/04/22/news/sicilia_agricoltura_azienda_passo_ladro_pino_lops-297381732/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Repubblica />
                            </a>
                        </div>

                        {/* <Modal ref={modalRef1}>
                            <Form />
                        </Modal> */}
                    </div>
                </section>

                {/* <section className="flex flex-wrap items-center justify-center mt-16">
                    <span className="scroll-icon">
                        <span className="scroll-icon__dot"></span>
                    </span>
                </section> */}

                <section className="flex flex-wrap items-center justify-center mt-5">
                    <div className="text-center mt-5 mx-0 w-full">
                        <hr />
                        <h2 className="mx-0 px-4 text-5xl text-center text-gray-700 font-serif tracking-wide">
                            Come funziona
                        </h2>
                        <hr />
                        <div className="mt-5 mb-10" alt="Passo Ladro">
                            <video
                                controls
                                autoPlay
                                muted
                                playsInline
                                loop
                                height="720"
                                width="1280"
                                className="block m-auto"
                            >
                                <source
                                    src="./videos/pua.webm"
                                    type="video/webm"
                                />
                                <source
                                    src="./videos/pua.mp4"
                                    type="video/mp4"
                                />
                                {/* https://vimeo.com/500950123 */}
                            </video>
                        </div>
                    </div>
                </section>
                <section className="grid grid-flow-row grid-cols-1 md:grid-cols-3 gap-3 text-center border-black border-2">
                    <div className="flex flex-col justify-around justify-self-center w-full">
                        <p className="px-4 mt-2">
                            <span className="text-2xl text-white font-sans uppercase bg-black">
                                Step 1
                            </span>
                        </p>
                        <p className="text-center">
                            <PL1 />
                        </p>
                        <p className="px-4 pt-4">
                            <span className="text-3xl text-black font-serif">
                                Registrati o accedi sul nostro sito
                            </span>
                        </p>
                    </div>
                    <div className="flex flex-col justify-around justify-self-center w-full">
                        <p className="px-4 mt-2">
                            <span className="text-2xl text-white font-sans uppercase bg-black">
                                Step 2
                            </span>
                        </p>
                        <p className="text-center">
                            <PL2 />
                        </p>
                        <p className="px-4 pt-4">
                            <span className="text-3xl text-black font-serif">
                                Vai su "Porta Un Amico" nella sezione "Mio
                                Account" per vedere il tuo codice
                            </span>
                        </p>
                    </div>
                    <div className="flex flex-col justify-around justify-self-center w-full">
                        <p className="px-4 mt-2">
                            <span className="text-2xl text-white font-sans uppercase bg-black">
                                Step 3
                            </span>
                        </p>
                        <p className="text-center">
                            <PL3 />
                        </p>
                        <p className="px-4 pt-4">
                            <span className="text-3xl text-black font-serif">
                                Dai il tuo codice per far iscrivere un amico
                            </span>
                        </p>
                    </div>
                </section>
                <section className="flex flex-wrap items-center justify-center mt-5 mb-8">
                    <div className="text-center mt-5 mx-0 w-full">
                        <p className="mt-3 text-2xl font-medium">
                            L'amico che riceve il tuo codice e si registra su
                            Passo Ladro otterrà immediatamente <span className="text-white bg-black">15€ di sconto</span> per
                            il suo primo ordine. Una volta concluso l'ordine
                            verrai premiato con <span className="text-white bg-black">15€ di sconto</span> per i tuoi
                            acquisti futuri, è il nostro modo per ringraziarti!
                        </p>
                        <div className="px-4 mt-8 text-center">
                            {/* <div className="max-w-4xl mx-auto"> */}
                            <a href="https://passoladro.it/registrazione/">
                                <button className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent">
                                    {/* <Link to="/iscriviti"> */}
                                    <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                        Scopri il tuo codice amico
                                    </h2>
                                    {/* </Link> */}
                                </button>
                            </a>
                            {/* </div> */}
                        </div>
                    </div>
                </section>

                {/* <section className="flex flex-wrap items-center justify-center mt-5">
                    <div className="text-center mt-5 mx-0 w-full">
                        <h2 className="mb-2 px-4 text-5xl text-center text-black font-serif tracking-wide">
                            Come funziona
                        </h2>
                        <PortaUnAmico2 />
                    </div>
                </section> */}

                {/* <section className="flex flex-wrap items-center justify-center mt-5">
                    <div className="text-center mt-5 mx-0 w-full">
                        <h2 className="mb-2 px-4 text-5xl text-center text-black font-serif tracking-wide">
                            Perché sceglierci
                        </h2>
                        <Badges />
                    </div>
                </section> */}
                <PortaUnAmico2 />
                <h2 className="mt-10 mx-0 mb-8 px-4 text-5xl text-center text-gray-700 font-serif tracking-wide">
                    Perché sceglierci
                </h2>
                <section className="grid grid-flow-row grid-cols-1 md:grid-cols-5 gap-3 text-center">
                    <div className="flex flex-col justify-between justify-self-center w-full">
                        <p className="text-center">
                            <PL4 />
                        </p>
                        <p className="px-4 pt-4">
                            <span className="text-3xl md:text-xl text-black font-sans">
                                I nostri prodotti sono certificati Bioagricert
                            </span>
                        </p>
                    </div>
                    <div className="flex flex-col justify-between justify-self-center w-full">
                        <p className="text-center">
                            <PL7 />
                        </p>
                        <p className="px-4 pt-4">
                            <span className="text-3xl md:text-xl text-black font-sans">
                                Non usiamo pesticidi o sostanze dannose
                            </span>
                        </p>
                    </div>
                    <div className="flex flex-col justify-between justify-self-center w-full">
                        <p className="text-center">
                            <PL6 />
                        </p>
                        <p className="px-4 pt-4">
                            <span className="text-3xl md:text-xl text-black font-sans">
                                Abbiamo solo materie prime d'eccellenza
                            </span>
                        </p>
                    </div>
                    <div className="flex flex-col justify-between justify-self-center w-full">
                        <p className="text-center">
                            <PL5 />
                        </p>
                        <p className="px-4 pt-4">
                            <span className="text-3xl md:text-xl text-black font-sans">
                                Prodotti gourmet preparati da chef esperti
                            </span>
                        </p>
                    </div>
                    <div className="flex flex-col justify-between justify-self-center w-full">
                        <p className="text-center">
                            <PL8 />
                        </p>
                        <p className="px-4 pt-4">
                            <span className="text-3xl md:text-xl text-black font-sans">
                                Rispettiamo il lavoro dei nostri contadini
                            </span>
                        </p>
                    </div>
                </section>

                {/* <section className="flex flex-wrap items-center justify-center mt-10 md:mt-20">
                    <div className="text-center mt-5 w-full">
                        <h2 className=" mx-0 mb-2 px-4 pt-5 text-5xl text-center text-black font-serif tracking-wide">
                            Domande frequenti
                        </h2>
                        <Accordion />
                    </div>
                </section> */}

                <section className="flex flex-wrap items-center justify-center mt-10 mb-10">
                    <div className="sm:px-2">
                        <hr />
                        <p className="px-4 text-2xl text-center text-black font-sans">
                            Approfitta adesso della promozione e porta un nuovo
                            brigante etico nella nostra famiglia
                        </p>
                        <hr />
                        <div className="px-4 mt-8 text-center">
                            <a href="https://passoladro.it/registrazione/">
                                <button className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent">
                                    <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                        Accedi al tuo account
                                    </h2>
                                </button>
                            </a>
                        </div>
                    </div>
                </section>

                {/* <div className="mx-auto relative px-0 lg:px-6">
                    <video controls autoPlay muted height="720" width="1280">
                        <source src="./videos/video.webm" type="video/webm" />
                        <source src="./videos/video.mp4" type="video/mp4" />
                        https://vimeo.com/500950123
                    </video>
                </div> */}

                {/* <div className="flex items-center p-3 mx-2 bg-white rounded shadow-xs sm:mx-auto">
                    <code className="text-gray-600 text-xs leading-xl">
                        gatsby new my-project-name
                        https://github.com/melanienolan/gatsby-starter-tailwind-css
                    </code>
                </div> */}
            </main>
        </Layout>
    );
};
